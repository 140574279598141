.nv-modalpopup .modal-header,
.nv-modalpopup .modal-footer {
  border: none;
}

.nv-modalpopup.modal.fade:not(.show) {
  opacity: 1;
}

.nv-modalpopup input {
  background: #ededed;
  box-shadow: none;
  max-width: 100%;
  color: #330066;
}

.nv-modalpopup .close {
  font-size: 24px;
  line-height: 10px;
  font-weight: 100;
  color: #330066;
  opacity: 1 !important;
}

.nv-modalpopup .nv-btnprimary {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 0px 30px !important;
  font-size: 12px;
  min-width: 116px;
  font-weight: bold;
  line-height: 36px;
}

.nv-modalpopup .modal-content {
  border-radius: 0px;
  border-color: #9b9b9c !important;
  border-top: 3px solid darkblue !important;
  padding: 0px 0px;

  .modal-footer div[slot='popup-footer'] {
    margin: 0px auto;
    width: 100%;
  }
}

.nv-modalpopup .modalfootlink {
  line-height: 40px;
  margin-bottom: 5px;
}

.nv-modalpopup .nv-btnlined {
  background: transparent;
  padding: 0px 0px !important;
}

$orange-color: orange;
$red-color: #e6154c;

.nv-modalpopup.redthemepopup .nv-modalpopup.modal.hidebackground {
  background: #330066;
}

.redthemepopup.nv-modalpopup {
  .modal-content {
    border-top: 3px solid $red-color !important;
  }

  .fa,
  .modal-header h3,
  .nv-primary,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .close {
    color: $red-color !important;
  }
}

.modal-dialog {
  min-width: 320px;
  display: inline-block;
  align-items: center !important;
  margin: 0px auto;
}

.nv-modalpopup.modal {
  background: #33006566;
  display: flex !important;
  align-items: center;
  align-content: center;
}

.nv-modalpopup .form-control {
  background-color: #ededed;
}

.nv-modalpopup .nv-input1 {
  width: 100%;
  max-width: initial;
}
