/* General */
$scale: 14px; /* main font size */
$borderRadius: 12px; /* border radius of layout element e.g. card, sidebar */
$transitionDuration: 0.2s; /* transition duration of layout elements e.g. sidebar, overlay menus */

:root {
  --primary-purple: #6b24c7;
  --secondary-purple: #5015b0;
  --secondary-color: var(--secondary-purple);

  /* Colors */
  --white: #fff;
  --purple-005: #4c227e;
  --purple-006: #391861;
  --gray-001: #f4f4f5;
  --gray-004: #9d9d9d;
  --gray-002: #e7e7e7;
  --purple-001: #f5efff;
  --label-light-primary: #000;

  --novatiq-blue: #330065;
  --novatiq-sidebar-text: #fff;
  --novatiq-sidebar-hover-color: var(--primary-color);
  --novatiq-sidebar-active: var(--primary-color);

  /* Border radiuses */
  --br-5xs: 8px;
  --button-border: 8px;
}
