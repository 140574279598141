.nv-taboverlay {
  background: white;
  display: inline-block;
  width: 100%;
}

.nv-tab {
  list-style: none;
  margin: 0px !important;
  width: 100%;
  float: left;
  display: flex;
  margin-block-start: 0em !important;
  padding-inline-start: 0px;
  li {
    float: left;
    position: relative;
    width: 30%;
    max-width: 220px;
    bottom: -10px;
    margin-right: 2px;
  }
}

.nv-tab li.active {
  bottom: 0px;
}

.nv-tab a {
  float: left;
  padding: 8px 25px;
  text-decoration: none;
  color: #330066;
  background: #d8d8d8;
  box-shadow: 0px 0px 5px #d6d6d6;
  -webkit-border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-radius-topleft: 15px;
  -moz-border-radius-topright: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  position: relative;
  top: -2px;
  margin-right: 1px;
}

.nv-box {
  height: 17vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  font-size: 14px;

  td {
    cursor: pointer;
  }
}

.nv-card {
  width: 14rem;
}

.nv-tab .active a {
  background: #6a23c9;
  color: #ffffff;
  padding: 12px 25px;
  top: 0px;
}

.nv-tab .active a:hover {
  background: #6a23c9;
  color: #ffffff !important;
  text-decoration: none;
}

.nv-tab a:hover,
.nv-tab a:active,
.nv-tab a:focus {
  color: #330066 !important;
  outline: none;
  text-decoration: none;
}

.nv-taboverlay h1 {
  margin-top: 45px;
  margin-bottom: 22px;
  font-size: 36px;
}

.nv-taboverlay h5 {
  color: #4e09b0;
  font-size: 12px;
}
