/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import 'assets/layout/styles/layout/layout.scss';

@import '/node_modules/bootstrap/dist/css/bootstrap.min.css' layer(bootstrap);
@import '/node_modules/ngx-toastr/toastr';
@import '/node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';

/* PrimeNG */
@import '/node_modules/primeng/resources/primeng.css';
@import '/node_modules/primeflex/primeflex.scss';
@import '/node_modules/primeicons/primeicons.css';

// import Quill styles in styles.scss
@import '/node_modules/quill/dist/quill.core.css';
@import '/node_modules/quill/dist/quill.snow.css';

/* Demos */
@import 'scss/styles.scss';
