.layout-sidebar {
  position: fixed;
  width: 268px;
  height: calc(100vh - 4rem);
  //height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  top: 4rem;
  transition: transform $transitionDuration, left $transitionDuration;
  //background-color: var(--surface-overlay);
  background-color: var(--purple-006);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.layout-menu {
  margin: 0;
  padding: 8px;
  list-style-type: none;

  .layout-root-menuitem {
    > .layout-menuitem-root-text {
      font-size: 0.857rem;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--surface-100);
      margin: 0.75rem 0;
    }

    > a {
      display: none;
    }
  }

  a {
    user-select: none;

    &.active-menuitem {
      > .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  li.active-menuitem {
    > a {
      .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      color: var(--novatiq-sidebar-text);
      cursor: pointer;
      //padding: 0.75rem 1rem;
      padding: 8px 16px 8px 8px;
      margin: 2px 0;
      //border-radius: $borderRadius;
      transition: background-color $transitionDuration, box-shadow $transitionDuration;

      .novatiq-menuitem-image {
        filter: brightness(0) saturate(100%) invert(67%) sepia(67%) saturate(6035%) hue-rotate(235deg) brightness(86%)
          contrast(82%);
      }

      .layout-menuitem-icon {
        margin-right: 1rem;
        font-size: 1.3rem;
        width: 15px;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform $transitionDuration;
      }

      .layout-menuitem-text {
        letter-spacing: 0.4px;
        line-height: 22px;
        color: var(--gray-001);
      }

      &.active-route {
        //font-weight: 700;
        //color: var(--novatiq-sidebar-active);
        border-radius: 8px;
        background-color: var(--novatiq-sidebar-active);
        color: var(--white);

        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
            contrast(103%);
        }

        .menuitem-selected {
          position: absolute;
          margin: 0 !important;
          top: 0;
          left: 6px;
          border-radius: 0 0 var(--br-5xs) var(--br-5xs);
          background-color: var(--white);
          width: 40px;
          height: 6px;
          transform: rotate(90deg);
          transform-origin: 0 0;
          z-index: 2;
        }
      }

      &:hover {
        // border-radius: 8px;
        // background-color: var(--novatiq-sidebar-hover-color);
        text-decoration: none;
      }

      &:focus {
        //@include focused-inset();
      }
    }
    /*hover effect for sidebar menu itmes starts here*/
    a::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #fff;
      transition: 0.2s;
    }

    a:hover::after {
      transform: scaleX(1);
    }

    /*hover effect ends here*/

    ul {
      overflow: hidden;
      //border-radius: $borderRadius;

      li {
        a {
          margin-left: 0.5rem;
        }

        li {
          a {
            margin-left: 1rem;
          }

          li {
            a {
              margin-left: 2rem;
            }

            li {
              a {
                margin-left: 2.5rem;
              }

              li {
                a {
                  margin-left: 3rem;
                }

                li {
                  a {
                    margin-left: 3.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

