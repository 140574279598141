/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: black #e5e5e5;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #e5e5e5;
}
*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
}
